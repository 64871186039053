import React from "react"
import {
  MDBJumbotron,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact"

const Jumbotron = () => {
  return (
    <MDBJumbotron
      fluid
      style={{ backgroundColor: "#f5f5f5", boxShadow: "none" }}
    >
      <MDBContainer fluid className="mt-5 text-center">
        <MDBRow>
          <MDBCol>
            <h1 className="h1">HackerSpace</h1>
            <p className="text-primary lead my-4">
            HackerSpace is non-profit coding community where anyone passionate about technology can get started on something to learn/create
           <br></br> Help spread hack culture in our college
           <br></br>Help,motivate and guide each other to create, innovate and showcase their potential
            </p>

            <hr className="my-4" style={{ width: "30rem" }} />
            
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>
  )
}

export default Jumbotron
