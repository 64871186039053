import React from "react"
import {
  MDBContainer,
} from "mdbreact"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbotron />
    <MDBContainer>
      
    </MDBContainer>
  </Layout>
)

export default IndexPage
